import AstDatepicker from "../ast-datepicker/AstDatepicker";
import errorToast from "../error-toast/errorToast";

export default formQuerySelector => {
  const form = document.querySelector(formQuerySelector);
  const startDateField = form.querySelector(".start-date-field");
  const startDateText = form.querySelector(".start-date-text");

  new AstDatepicker({
    field: startDateField,
    trigger: startDateText,
    dateFormat: "DD MMM YY",
    minDate: "today",
    locale: "id"
  });

  const carType = form.querySelector(".car-type");
  const facility = form.querySelector('[name="facility"]');
  const duration = form.querySelector('[name="duration"]');

  carType.addEventListener("change", e => {
    form.setAttribute("action", `/${carType.value}/booking`);
    createFacilityOptions(carType, facility);
  });

  facility.addEventListener("mousedown", e => {
    if (carType.value.length < 1)
      sendError(e, "Mohon isi jenis mobil terlebih dahulu", carType);
  });

  form.addEventListener("submit", e => {
    if (carType.value.length < 1) {
      return sendError(e, "Jenis mobil belum diisi", carType);
    } else if (facility.value.length < 1) {
      return sendError(e, "Fasilitas belum diisi", facility);
    } else if (startDateField.value.length < 1) {
      return sendError(e, "Tanggal mulai belum diisi", startDateText);
    } else if (duration.value.length < 1) {
      return sendError(e, "Durasi belum diisi", duration);
    }

    if (document.body.classList.contains("production")) {
      e.preventDefault();
      return gtag_report_conversion({
        conversionID: "AW-702169517/LZdiCLnGobEBEK2D6c4C",
        callback: () => form.submit()
      });
    }
  });
};

function sendError(event, errorText, el) {
  event.preventDefault();
  errorToast(errorText, el);
}

function createFacilityOptions(carTypeEl, facilityEl) {
  const facilitiesText = {
    manual: "Manual",
    matic: "Matic",
    driver: "Dengan Supir"
  };

  // Get the value before reset the options
  const facilityVal = facilityEl.value;

  // Reset facility options
  // Check if any options there besides the placeholder
  const facilityOptionsLength = facilityEl.options.length;
  if (facilityOptionsLength > 1) {
    // Start from last element
    // If start from first element, it would change the index of the element, so it would work incorrectly
    // End in index 1, because we don't want to remove the placeholder text
    for (let i = facilityOptionsLength - 1; i >= 1; i--) {
      facilityEl.options.remove(i);
    }
  }

  const selectedCarType = carTypeEl.options[carTypeEl.selectedIndex];
  const availableFacilities = selectedCarType.dataset.facilities.split("-");

  let selectedIndex = 0;
  availableFacilities.forEach((facility, index) => {
    facilityEl.options.add(new Option(facilitiesText[facility], facility));

    // If facility have value before reseted and one of the newly generated options is that value
    // make it selectedIndex
    // Otherwise, give the 0 index
    if (facilityVal.length > 0 && facility === facilityVal)
      // Need + 1, because there's a placeholder value in the options
      selectedIndex = index + 1;
  });

  facilityEl.options[selectedIndex].selected = true;
}
