import "./error-toast.css";

const htmlToElement = html => {
  const template = document.createElement("template");
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;
  return template.content.firstChild;
};

const whichTransitionEvent = () => {
  const el = document.createElement("fakeelement");
  const transitions = {
    transition: "transitionend",
    OTransition: "oTransitionEnd",
    MozTransition: "transitionend",
    WebkitTransition: "webkitTransitionEnd"
  };

  for (let t in transitions) {
    if (el.style[t] !== undefined) {
      return transitions[t];
    }
  }
};

const offset = el => {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
};

export default (text, element, timeout = 5000) => {
  propsCheck(element, timeout);

  let isFullToast = false;
  if (!element || window.innerWidth < 768) isFullToast = true;

  const toastElement = createToastElement(text, isFullToast);

  if (!isFullToast) {
    showToast(element, toastElement, timeout);
  } else {
    showFullToast(toastElement, timeout);
  }
};

function propsCheck(element, timeout) {
  if (
    element &&
    !(element instanceof HTMLElement) &&
    typeof element !== "string"
  )
    throw new Error(
      `'element' should be an "HTMLELement" or "String" of element query selector`
    );

  if (typeof timeout !== "number")
    throw new Error(`'timeout' should be a "Number" of time in milliseconds`);
}

function createToastElement(text, isFull) {
  return htmlToElement(`
    <div class="error-toast ${isFull ? "error-toast--full" : ""}">
      <div class="error-toast__inner">${text}</div>
    </div>
  `);
}

function showToast(element, toastElement, timeout) {
  // Get the Rect Info of the specified element on querySelector
  // to get the left, top, and height of the element
  // The Rect Info is used for positioning the Toast Element
  const destElement = destElementCheck(element);
  const destElementOffset = offset(destElement);
  toastElement.style.left = `${destElementOffset.left}px`;
  toastElement.style.top = `${destElementOffset.top +
    destElement.offsetHeight}px`;

  document.body.appendChild(toastElement);

  setTimeout(() => {
    toastElement.parentNode.removeChild(toastElement);
  }, timeout);
}

function showFullToast(toastElement, timeout) {
  // Add the element as new child on body
  document.body.appendChild(toastElement);

  // Add class 'show' with setTimeout to make it have a delay
  // becase with setTimeout, the callback will be on event loop
  setTimeout(() => {
    toastElement.classList.add("error-toast--show");
  }, 0);

  // Hide the element after timeout
  setTimeout(() => {
    toastElement.classList.remove("error-toast--show");
  }, timeout);

  // Remove the element after hide element transition
  const transitionEvent = whichTransitionEvent();
  transitionEvent &&
    toastElement.addEventListener(transitionEvent, e => {
      if (!toastElement.classList.contains("error-toast--show"))
        toastElement.parentNode.removeChild(toastElement);
    });
}

function destElementCheck(element) {
  let destElement;

  if (typeof element === "string")
    destElement = document.querySelector(element);
  else destElement = element;

  if (!destElement) throw new Error("Destination element not found");

  return destElement;
}
