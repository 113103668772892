import { Swiper, Pagination } from "swiper/js/swiper.esm";
import "swiper/css/swiper.css";

Swiper.use([Pagination]);

export default () => {
  new Swiper(".hm-car-list .swiper-container", {
    slidesPerView: 1,
    spaceBetween: 24,
    pagination: {
      el: ".hm-car-list__pagination",
      clickable: true,
      dynamicBullets: true,
      dynamicMainBullets: 5
    },
    breakpoints: {
      421: {
        slidesPerView: 2
      },
      641: {
        slidesPerView: 3,
        spaceBetween: 32
      },
      1281: {
        slidesPerView: 4,
        spaceBetween: 38
      }
    }
  });
};
