import "waypoints/lib/noframework.waypoints";

export default class {
  constructor({ offset = 0 }) {
    this.options = { offset };
    this.els = document.querySelectorAll(".reveal-item");
    if (this.els.length < 1) return;

    this.createWaypoints();
  }

  createWaypoints() {
    this.els.forEach(el => {
      const offset =
        el.dataset.rosOffset != null
          ? el.dataset.rosOffset
          : this.options.offset;

      const target = el.dataset.rosTarget;

      let element;
      if (target) element = document.querySelector(target);
      else element = el;

      const waypoint = new Waypoint({
        element,
        handler: () => {
          el.classList.add("reveal-item--is-visible");
          waypoint.destroy();
        },
        offset
      });
    });
  }

  refreshAll() {
    Waypoint.refreshAll();
  }
}
