// Use custom pikaday to disable moment.js
import Pikaday from "./pikaday/pikaday";
import "./pikaday/pikaday.css";
import dayjs from "dayjs";

export default class {
  constructor(
    options = {
      field,
      trigger,
      textField,
      dateFormat,
      minDate,
      locale,
      customOptions
    }
  ) {
    this.options = options;

    this.propsCheck();

    return this.initPicker();
  }

  propsCheck() {
    if (!this.options.field) throw new Error(`'field' is required`);

    if (!(this.options.field instanceof HTMLInputElement))
      throw new Error(`'field' should be an "Input Element"`);

    if (this.options.trigger && !(this.options.trigger instanceof HTMLElement))
      throw new Error(`'trigger' should be an "HTML Element"`);

    if (
      this.options.textField &&
      !(this.options.textField instanceof HTMLElement)
    )
      throw new Error(`'textField' should be an "HTML Element"`);

    if (this.options.dateFormat && typeof this.options.dateFormat !== "string")
      throw new Error(`'dateFormat' should be a "String"`);

    if (
      this.options.minDate &&
      !(this.options.minDate instanceof Date) &&
      typeof this.options.minDate !== "string"
    ) {
      throw new Error(
        `'minDate' should be a "Date" or a "String" of "Today" or "Tomorrow"`
      );
    }

    if (this.options.locale && typeof this.options.locale !== "string")
      throw new Error(`'locale' should be a "String"`);

    if (
      this.options.customOptions &&
      typeof this.options.customOptions !== "object"
    )
      throw new Error(`'customOptions' should be an "Object"`);
  }

  async initPicker() {
    // Locale check
    if (this.options.locale.toLowerCase() !== "en") {
      try {
        const { default: i18n } = await import(
          /* webpackChunkName: "ast-datepicker-locale" */ `./locales/${this.options.locale}`
        );
        this.options.customOptions = {
          i18n,
          ...this.options.customOptions
        };
      } catch {
        throw new Error(`${this.options.locale} locale not found`);
      }
    }

    const picker = new Pikaday({
      field: this.options.field,
      trigger: this.options.trigger,
      minDate: this.minDateSetup(),
      onSelect: date => this.onSelectDate(date),
      onOpen: function() {
        if (picker.el.offsetLeft < 0) picker.el.style.left = "0px";
      },
      ...this.options.customOptions
    });

    return picker;
  }

  minDateSetup() {
    if (!this.options.minDate) return null;

    if (this.options.minDate instanceof Date) return this.options.minDate;

    const today = new Date();
    if (this.options.minDate.toLowerCase() === "today") return today;
    else if (this.options.minDate.toLowerCase() === "tomorrow") {
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);
      return tomorrow;
    } else {
      throw new Error(`'minDate' options only "today" or "tomorrow"`);
    }
  }

  onSelectDate(date) {
    let valueField;
    if (this.options.textField) valueField = this.options.textField;
    else if (this.options.trigger) valueField = this.options.trigger;
    else valueField = this.options.field;

    let value;
    if (this.options.dateFormat)
      value = dayjs(date).format(this.options.dateFormat);
    else value = picker.toString();

    this.setValue(valueField, value);
    this.options.field.value = dayjs(date).format("YYYY-MM-DD");
  }

  setValue(field, value) {
    if (field instanceof HTMLInputElement) field.value = value;
    else field.textContent = value;
  }
}
