import carSlider from "../modules/home/carSlider";
import headerBanner from "../modules/home/headerBanner";
import innerForm from "../modules/home/innerForm";
import mobileForm from "../modules/home/mobileForm";
import RevealOnScroll from "../modules/RevealOnScroll";

headerBanner();

innerForm(".hm-banner__input");

innerForm(".hm-mobile-form__content");
mobileForm();

const revealOnScroll = new RevealOnScroll({ offset: "85%" });

window.onload = () => {
  carSlider();
  revealOnScroll.refreshAll();
};
