export default () => {
  const searchButton = document.querySelector(".hm-banner__search");
  const mobileForm = document.querySelector(".hm-mobile-form");
  searchButton.addEventListener("click", e => {
    e.preventDefault();
    mobileForm.classList.add("open");
    document.body.style.overflow = "hidden";
  });

  const closeButton = document.querySelector(".hm-mobile-form__close-btn");
  closeButton.addEventListener("click", e => {
    e.preventDefault();
    mobileForm.classList.remove("open");
    document.body.style = null;
  });
};
