const offset = el => {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
};

export default () => {
  const header = document.querySelector(".header");
  const isPortrait = window.innerHeight > window.innerWidth;

  let scrollOffset;
  if (isPortrait) {
    const bannerRight = document.querySelector(".hm-banner__right");
    const bannerRightOffset = offset(bannerRight);
    scrollOffset = bannerRightOffset.top + 50;
  } else {
    scrollOffset = window.innerHeight;
  }

  window.addEventListener("scroll", () => {
    if (window.pageYOffset > scrollOffset) {
      header.classList.remove("header-home-page");
    } else {
      header.classList.add("header-home-page");
    }
  });
};
